
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Actions } from '@frontcommon/shared/src/almacen/enums/StoreEnums';
import { loginRequest } from '@frontcommon/shared/src/interfaces/nostromo/service/loginRequest';
import ForgotPassword from './ForgotPassword.vue';
import CookieService from '@frontcommon/shared/src/servicios/CookieService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import HelperUtils from '@frontcommon/shared/src/funciones/HelperUtils';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import HelperConfiguration from '@frontcommon/shared/src/funciones/HelperConfiguration';
import Cargando  from '@frontcommon/shared/src/componentes/base/common/cargando/Cargando.vue';
import logoComp from '@frontcommon/shared/src/funcionescompartidas/logoComp';
import ConfigurationConst from '@frontcommon/shared/src/constantes/ConfigurationConst';
import PlatformType from '@frontcommon/shared/src/constantes/PlatformType';

export default defineComponent({
    components: { ForgotPassword, Cargando },
    name: 'login',
    setup() {
        const {getLogoPanelUrl, getLogoUrl, ipublicPath, platform} = logoComp();
        
        const store = useStore();
        const router = useRouter();
        const submitButton = ref<HTMLElement | null>(null);
        const bodyLogin = ref<HTMLElement | null>(null);
        const emailControl = ref<HTMLElement | null>(null);
        const pwdControl = ref<HTMLElement | null>(null);
        let isLoading = ref<boolean>(false);
        let index = 0;
        const isForgetPass = ref(false);
        const submitted = ref(false);
        const version = ref('');
        const cargando = ref();

        const request = ref<loginRequest>({
            email: '',
            password: '',
            remoteApplication: 'Portalv2',
            remoteIp: '',
            requestedUrl: '',
        });


        const customEmail = value => {
            return HelperCommon.validarEmail(value);
        }


        const rules = {
            password: { required: helpers.withMessage('El password es obligatorio', required) },
            email: {
                required: helpers.withMessage('El usuario es obligatorio', required),
                customEmail: helpers.withMessage('Correo no válido', customEmail),
            },
        };


        const v$ = useVuelidate(rules, request);

        const getVersion = () => {
            cargando.value.mostrar();
            HelperConfiguration.getVersion().then((data) => {
                version.value = data.version;
                platform.value = data.platform;
            }).finally(()=>{
                cargando.value.ocultar();
            })
        }        


        onMounted(() => {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
             */
            Swal.close();
            getVersion();
            pageLoad();
        });

        const handleSubmit = (isFormValid) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }


        }

        const isValidLoginData = (): boolean => {
            submitted.value = true;
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }
        const showLoading = computed(() => {
            return isLoading.value;
        });

        //Form submit function
        const onSubmitLogin = () => {
            if (isValidLoginData()) {
                setTimeout(() => {
                    isLoading.value = true;
                    cargando.value.mostrar();
                    store
                        .dispatch(Actions.LOGIN, request.value)
                        .then(() => {

                            var redirectCookie = CookieService.getCookie(CookieService.COOKIE_REDIRECT_URL) ?? '';
                            if (redirectCookie != null && redirectCookie != '') {
                                CookieService.destroy(CookieService.COOKIE_REDIRECT_URL);
                                // para las locales
                                if (ipublicPath !== '/'){
                                    router.push(redirectCookie.replace((ipublicPath + ''),''));                                                                                              
                                }else{
                                    router.push(redirectCookie);                                                                                              
                                }
                            } 
                            else 
                            {                            
                                var initUrl = store.getters.initUrlPage;
                                if (initUrl == '') {
                                    const id = HelperUtils.newGuid();
                                    router.push({ name: 'rfps', params: { id: id, operation: 'productos' } });
                                } else {
                                    router.push({ name: initUrl == 'default.aspx' ? 'mipanel' : initUrl });
                                }
                            }
                        })
                        .finally(() => {
                            isLoading.value = false;
                            cargando.value.ocultar();
                        });
                }, 500);
            }

        };

        const onSubmitForgotPassword = () => {
            // Dummy delay
            setTimeout(() => {
                // Send login request
                store.dispatch(Actions.FORGOT_PASSWORD, request.value).then(() => {
                    router.push({ name: 'mipanel' });
                });

                //Deactivate indicator
            }, 100);
        };

        function pageLoad() {
            carousel();
        }

        const getUrlLoginImages = ():string => {
            let urlLogo = `${ipublicPath}layout/images/pages/login/WillPlatine`;
            var platform = store.getters.getKey(ConfigurationConst.PLATFORM) ??  PlatformType.WP;            
            if (platform.value){                   
                if (platform.value.toLowerCase() === PlatformType.AM ){
                    urlLogo = `${ipublicPath}layout/images/pages/login/AunnaManager`;
                }
            }
            return urlLogo;
        }
        const getUrlLoginImage = ():string => {
            return getUrlLoginImages() + '/bgw1.jpg';
        }

        function carousel() {
            if (router.currentRoute.value.path == '/login' && bodyLogin.value != null) {
                if (index > 5) {
                    index = 0;
                }
                index++;
                        
                const urlLogo = getUrlLoginImages();

                if (index === 1) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw1.jpg) center top / cover no-repeat fixed';
                } else if (index === 2) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw2.jpg) center top / cover no-repeat fixed';
                } else if (index === 3) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw3.jpg) center top / cover no-repeat fixed';
                } else if (index === 4) {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw4.jpg) center top / cover no-repeat fixed';
                } else {
                    bodyLogin.value.style.background = 'url(' + urlLogo + '/bgw5.jpg) center top / cover no-repeat fixed';
                }
            }
            setTimeout(carousel, 30000);
        }


        return {
            request,
            onSubmitLogin,
            onSubmitForgotPassword,
            submitButton,
            bodyLogin,
            carousel,
            pageLoad,
            isForgetPass,

            emailControl,
            pwdControl,
            showLoading,
            isLoading,
            ipublicPath,
            v$,
            isValidLoginData,
            submitted,
            handleSubmit,
            version,
            getLogoPanelUrl,
            getLogoUrl,
            getVersion,
            platform,
            cargando,
            getUrlLoginImage
        };
    },
});
