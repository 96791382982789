export default class rfpStatusTypeConst {
static pte_tarificar = 'pte_tarificar'; 
static pte_solicitar_cia = 'pte_solicitar_cia'; 
static pte_respuesta_cia = 'pte_respuesta_cia'; 
static pte_presentar_cliente = 'pte_presentar_cliente'; 
static pte_respuesta_cliente = 'pte_respuesta_cliente'; 
static hibernada = 'hibernada'; 
static pte_respuesta_emision = 'pte_respuesta_emision'; 
static pte_emision = 'pte_emision'; 
static rechazado_cliente = 'rechazado_cliente'; 
static poliza_emitida = 'poliza_emitida'; 
static poliza_rechazada = 'poliza_rechazada'; 
}