export enum rfpStatusType {
    pte_tarificar,
    pte_solicitar_cia,
    pte_respuesta_cia,
    pte_presentar_cliente,
    pte_respuesta_cliente,
    hibernada,
    pte_respuesta_emision,
    pte_emision,
    rechazado_cliente,
    poliza_emitida,
    poliza_rechazada
}