import { Actions } from '../enums/StoreEnums';
import { Module, Action, VuexModule } from 'vuex-module-decorators';
import HelperSecurity from '../../funciones/HelperSecurity';


@Module
export default class EncryptionModule extends VuexModule  {
    
    @Action
    [Actions.ENCRYPT](input) {
        return new Promise<string>((resolve, reject) => {
            HelperSecurity.doEncrypt(this.context.getters.sessionID,true,input)
            
            .then(( value) => {                    
                resolve(value);                    
            })
            .catch(() => {
                reject();
            });

        })
    }    

    @Action
    [Actions.DECRYPT](input) {
        return new Promise<string>((resolve, reject) => {
            HelperSecurity.doEncrypt(this.context.getters.sessionID,false,input)
            
            .then(( value) => {                    
                resolve(value);                    
            })
            .catch(() => {
                reject();
            });

        })
    }    

    
}


