
import AppSubmenu from './AppSubmenu.vue';

import { ref } from '@vue/reactivity';
import { defineComponent, computed } from 'vue';
import { menu } from '@frontcommon/shared/src/modelos/menu/Menu';
import { useStore } from 'vuex';

export default defineComponent({
    components: { AppSubmenu: AppSubmenu, },
    name: 'app_menu',
    props: {
        layoutMode: String,
        active: Boolean,
    },
    setup() {
        const store = useStore();

        let menus = ref<menu[]>();

        const initMenus = () => {
            const data = store.getters.menuUser();
            //tratar esta parte de nuevo cuando se decida incluir el menú del erp de gestión. Por ahora se ha decidido solo un menú y ponerlo al final.
            let longitud = data.length;
            if (longitud > 1) {
                if ((data[longitud - 1].label === "Clientes") && (process.env.VUE_APP_CLIENTES === "false")) {
                    data.pop();
                }
            }            
            //------------------------------------------------------------------------------------------------------------------------------------------
            menus.value = data;
        }

        const filterMenu = computed(() => {
            if (store.getters.currentUser) {
                initMenus();

                return menus.value;
            }
            return [];
        });



        return {
            menus,
            filterMenu,
            initMenus
        };
    },

});
