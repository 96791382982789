

import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getCommunicationsRequest } from '@frontcommon/shared/src/interfaces/nostromo/service/getRfpNotificationRequest';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import EventBus from '@frontcommon/shared/src/event-bus';
import EventConst from '@frontcommon/shared/src/constantes/EventConst';
import Cargando from '../../../../shared/src/componentes/base/common/cargando/Cargando.vue';
import Sugerencias from '../../../../shared/src/componentes/base/portal/inicio/Sugerencias.vue';
import HelperExternal from '@frontcommon/shared/src/funciones/HelperExternal';
import HelperNotificaciones from '@frontcommon/shared/src/funciones/HelperNotificaciones';
import { communication } from '@frontcommon/shared/src/interfaces';

export default {
    name: 'AppActionBar',
    components: {
        Cargando,
        Sugerencias
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const saveInProgress = ref(false);
        const cargando = ref();
        const newsuggest = ref();
        const isError = ref(false);
        const notificaciones = ref<communication[]>();        
        let showTarificador = ref(false);


        if (store.getters.isUserAuthenticated) {
            getData();
            setInterval(function () {
                getData();
            }, 60000);
        }

        const correosConError = computed(() => {
            return notificaciones.value?.filter(c => c.rfpMailEvent != null)            
        }) 

        const isAdmin = computed( () => {
            return store.getters?.currentUser?.broker?.isAdmin;
        });

        function goToNotificaciones(tipo: string | null = null) {            
            router.push({ name: 'notificaciones', params: { tipo: tipo} });            
        }
        function getData() {
            if (store.getters.isUserAuthenticated) {
                const request:getCommunicationsRequest = ({
                    sessionID: store.getters.sessionID,
                    all: false,
                    id: '',
                    bcFleetRequestId: '',
                    rfpId: '',
                    rfpVersion: null,
                    createdByUserId: '',
                    notificateToUserId: store.getters.currentUser.id,
                    fromNotificationDate: '',
                    toNotificationDate: '',
                    seen: false,
                });

                HelperNotificaciones.GetCommunications(request).then((response) => {  
                        notificaciones.value = response.communications;
                    });
            }
        }


        const irAlPortal = () => {
            store.getters.encrypt('sid=' + store.getters.sessionID)
                .then(({ data }) => {
                    window.open(process.env.VUE_APP_PORTAL_URL + '?query=' + encodeURIComponent(data), '_blank');
                })

        };

        const mostrarSalesManager = () => {
            cargando.value.mostrar();
            HelperExternal.getExternalUrl(store.getters.sessionID, store.getters.currentUser.id, store.getters.currentUser.broker.id, 'avant')
                .then((url) => {
                    if (url) {
                        window.open(url, '_blank');
                    }
                    else {
                        window.open(process.env.VUE_APP_SALESMANAGER_URL ?? '', '_blank');
                    }
                }).finally(() => {
                    cargando.value.ocultar();
                })

        };

        function salir() {
            HelperCommon.salir(store.getters.sessionID);
        }


        const userName = computed(() => {
            return store.getters.currentUser.name;
        });
        const userEmail = computed(() => {
            return store.getters.currentUser.email;
        });
        const getBrokerLogo = computed(() => {
            return store.getters.currentBrokerLogo ?? '';
        });

        const openNewSuggest = (event, error) => {
            if (newsuggest.value) {
                newsuggest.value.toggle(event);
                isError.value = error;
            }
        }

        const closeSuggest = () => {
            if (newsuggest.value) {
                newsuggest.value.hide();
                isError.value = false;
            }
        }

        


        onMounted(() => {
            EventBus.on(EventConst.SAVE, (saving: boolean) => {
                if (saving) {
                    saveInProgress.value = saving;
                }
                else {
                    setTimeout(() => {
                        saveInProgress.value = saving
                    }, 1000);
                }
            });
            EventBus.on(EventConst.LOADING, (show: boolean) => {
                if (show) {
                    if (cargando.value) {
                        cargando.value.mostrar();
                    }

                }
                else {
                    if (cargando.value) {
                        cargando.value.ocultar();
                    }
                }
            });

        })



        return {
            correosConError,
            notificaciones,
            goToNotificaciones,
            getBrokerLogo,
            userName,
            userEmail,
            salir,
            showTarificador,

            irAlPortal,
            mostrarSalesManager,
            saveInProgress,
            cargando,
            newsuggest,
            openNewSuggest,
            closeSuggest,
            isError,
            isAdmin
        };
    },
};
