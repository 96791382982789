import ApiNostromo from '../servicios/ApiNostromo';

import { user } from '../interfaces/nostromo/service/generic/user';
import { encryptRequest } from '../interfaces/nostromo/service/encryptRequest';
import { setPasswordRequest } from '../interfaces/nostromo/service/setPasswordRequest';
import { setPasswordResponse } from '../interfaces/nostromo/service/setPasswordResponse';
import { getOptionsResponse } from '../interfaces/nostromo/service/getOptionsResponse';
import { getOptionsRequest } from '../interfaces/nostromo/service/getOptionsRequest';
import { getGroupsRequest } from '../interfaces/nostromo/service/getGroupsRequest';
import { getDirectivesRequest } from '../interfaces/nostromo/service/getDirectivesRequest';

import { group } from '../interfaces/nostromo/service/generic/group';

import { menu, menuItem } from '../modelos/menu/Menu';
import DirectiveConst from '../constantes/DirectiveConst';
import { directive } from '../interfaces/nostromo/service/generic/directive';
import { validateDirectivesRequest } from '../interfaces/nostromo/service/validateDirectivesRequest';
import { validateDirectivesResponse } from '../interfaces/nostromo/service/validateDirectivesResponse';
import { permission } from '../interfaces/nostromo/service/generic/permission';

/**
 * @description validate if can access to route
 */
export const checkAccess = (builtIn: string, contract: string, directives: string, user: user): boolean => {
    //Si la página está definida como builtin, sólo permitimos su acceso al usuario del corredor admin
    if (builtIn && builtIn != '') {
        if (builtIn == 'true') {
            if (!user?.broker?.isAdmin) {
                return false;
            }
        }
    }

    //Si la página está definida como contract, sólo permitimos su acceso a usuarios de corredores con contrato
    if (contract && contract != '') {
        if (contract == 'true') {
            if (!user?.broker?.contractWillPlatine) {
                return false;
            }
        }
    }

    if (directives && directives != '') {
        let validDirective = true;
        const split = directives.split(';');
        // Vamos validando las directivas especificadas. Si alguna está denegada, rechazamos acceso
        split.forEach(function (s) {
            if (s.trim() != '') {
                if (!validateDirective(user, s)) {
                    validDirective = false;
                    return false;
                }
            }
        });

        // Si la directiva no ha sido denegada (O si no habían directivas a consultar), damos acceso.
        return validDirective;
    }

    return true;
};

/**
 * @description validate if can access to route
 */
export const canUserAccess = (_name: string, routes: any, user: user): boolean => {
    //const user: user = store.getters.currentUser;
    const routeSearch = routes.filter((x) => x.name == _name);
    // si no se encuentra la ruta no se tiene acceso
    if (routeSearch.length != 1) {
        return false;
    }

    const route = routeSearch[0];
    const builtIn = route?.meta?.builtin;
    const contract = route?.meta?.contrato;
    const directives = route?.meta?.directivas;

    return checkAccess(builtIn, contract, directives, user);
};

/**
 * @description validate if can access to route
 */

export const buildUserMenu = (user: user, menus: menu[]): menu[] => {
    const data: menu[] = [];

    menus.forEach(function (value) {
        const itemsFiltered = value.items.filter((x) => {
            return checkAccess(x.builtin, x.contrato, x.directivas, user);
        });

        if (itemsFiltered.length > 0) {
            value.items = itemsFiltered;
            data.push(value);
        }
    });

    return data;
};

/**
 * @description validate user directive
 */
const validateDirective = (user: user, directive: string): boolean => {
    if (user?.broker?.isAdmin) {
        return true;
    }

    let directiveAllowed = false;
    const deniedQuery = user?.directives?.filter((x) => x.name.toLowerCase() == directive.toLowerCase() && x.allowed == false);

    if (deniedQuery == null || deniedQuery.length == 0) {
        //Si la directiva no está denegada, buscamos si está permitida
        const allowedQuery = user?.directives?.filter((x) => x.name.toLowerCase() == directive.toLowerCase() && x.allowed == true);
        if (allowedQuery == null || allowedQuery.length == 0) {
            //Si la directiva no está definida (ni denegada ni permitida), denegamos el acceso a la página
            directiveAllowed = false;
        } else {
            //Si la directiva está permitida, damos acceso
            directiveAllowed = true;
        }
    }
    return directiveAllowed;
};

/**
 * @description validate if can access to route
 */

export const findMenu = (key: string, menus: menu[]): menuItem | null => {
    let menu: menuItem | null = null;
    menus.forEach(function (value) {
        if (menu == null) {
            const itemsFiltered = value.items.filter((x) => {
                return x.to == key;
            });

            if (itemsFiltered.length > 0) {
                menu = itemsFiltered[0];
            }
        }
    });

    return menu;
};

/// Parsea una query
const parseQuery = (queryString: string): any => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

const getQueryParameter = (key: string, queryString: string): string => {
    return parseQuery(queryString)[key];
};

const doEncrypt = (sessionID: string, encrypt: boolean, queryString: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        if (sessionID == '') {
            resolve('');
        } else {
            const request: encryptRequest = {
                encrypt: encrypt,
                data: queryString,
                sessionID: sessionID,
            };
            ApiNostromo.post('/Encryption/doEncrypt', request as any)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(() => {
                    reject();
                });
        }
    });
};

const SetPassword = (request: setPasswordRequest): Promise<setPasswordResponse> => {
    return new Promise<setPasswordResponse>((resolve, reject) => {
        ApiNostromo.post('/security/setPassword', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};
const GetOptions = (sessionId, names: string[]): Promise<getOptionsResponse> => {
    return new Promise<getOptionsResponse>((resolve, reject) => {
        const request: Partial<getOptionsRequest> = {
            sessionID: sessionId,
            all: false,
            names: names,
        };
        ApiNostromo.post('/security/getOptions', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

const logout = (sessionID): Promise<boolean> => {    
    return new Promise<boolean>((resolve, reject) => {
        const data: any = {
            sessionId: sessionID,
        };
        ApiNostromo.post('/security/logout', data)
            .then(() => {                
                resolve(true);
            })
            .catch(() => {                
                reject();
            });
    });
};

const getGroups = (sessionID, id = null, name = null, parentId = null): Promise<group[]> => {
    return new Promise<group[]>((resolve, reject) => {
        const request: Partial<getGroupsRequest> = {
            sessionID: sessionID,
            id: id,
            name: name as any,
            parentId: parentId,
        };        
        ApiNostromo.post('/security/getGroups', request as any)
            .then((response) => {          
                return resolve(response.data.groups ?? []);
            })
            .catch(() => {                
                reject();
            });
    });
};

const hasAuthorizeIssuancePermission = (user) => {
    return validateDirective(user, DirectiveConst.AUTHORIZE_ISSUANCE);
};

const canShowLinkSIDOperation = (user: user) => {
    const groups = ['administrador', 'empleado'];
    if (user.groups) {
        return user.groups?.filter((x) => groups.includes(x.name.toLocaleLowerCase())).length > 0;
    }
    return false;
};

const GetDirectives = (sessionId: string): Promise<directive[]> => {
    return new Promise<directive[]>((resolve, reject) => {
        const request: Partial<getDirectivesRequest> = {
            sessionID: sessionId,
            all: true,        
        };
        ApiNostromo.post('/security/getDirectives', request as any)
            .then((response) => {
                resolve(response.data.directives ?? []);
            })
            .catch(() => {
                reject();
            });
    });
};

const ValidateDirectives = (sessionId: string, user: user, directives: directive[]): Promise<permission[]> => {
    return new Promise<permission[]>((resolve, reject) => {
        const request: Partial<validateDirectivesRequest> = {
            sessionID: sessionId,
            user: user,
            directives: directives        
        };
        ApiNostromo.post('/security/validateDirectives', request as any)
            .then((response) => {
                const validateDirectivesResponse:validateDirectivesResponse = response.data;
                resolve(validateDirectivesResponse.permissions ?? []);
            })
            .catch(() => {
                reject();
            });
    });
};

export default {
    canUserAccess,
    validateDirective,
    buildUserMenu,
    checkAccess,
    findMenu,
    parseQuery,
    getQueryParameter,
    doEncrypt,
    SetPassword,
    GetOptions,
    logout,
    hasAuthorizeIssuancePermission,
    canShowLinkSIDOperation,
    getGroups,
    GetDirectives,
    ValidateDirectives,
};
