
import ApiNostromo from "../servicios/ApiNostromo";
import MessageService, { MessageType } from "../servicios/MessageService";
import { newGuid } from "./HelperUtils";
import HelperCommon from './HelperCommon';
import { useRouter } from 'vue-router';




function setError(data)
{    
  if (data){
    ApiNostromo.post('/error/logErrWithoutSession', data);
  }

}

    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
const logException= (err, vm, info) : void => {     
    if(err && (err.message == 'Cannot read properties of null (reading \'style\')' || 
               err.message == 'Cannot set properties of null (setting \'value\')'))
    {
      return;
    }

    const  lastErrorRefId = newGuid();
      
      if (process.env.VUE_APP_DEBUGGING == 'true')
      {
        MessageService.showMessage(MessageType.Error,"Error en la aplicación",JSON.stringify(err.stack));  
      }
      else
      {
        const msg = 'ReferenciaId:' + lastErrorRefId + ' . Consulte el log para ver el detalle';
        MessageService.showMessage(MessageType.Error,"Error en la aplicación",msg);  
      }
      
      const data ={
        userAgent:navigator.userAgent,
        errorType:'Component Error',
        message:'Error en el componente',
        errmessage:err.message,
        stack:err.stack,
        commponent: vm,
        info:info
      }

      

      const trace = {
        error : JSON.stringify(data),
        errorRefId:lastErrorRefId,        
      };
      
      setError(trace);
}

const logExceptionW= (message, source, lineno, colno, error) : void => {
    // error en los calendar
  if (source && source.toString().includes('utils.esm.js')){
    return;
  }

  // error en los calendar  
  if (message && message == 'Uncaught TypeError: Cannot read properties of null (reading \'querySelector\')'){
    return;
  }


  const  lastErrorRefId = newGuid();
  const msg = 'ReferenciaId:' + lastErrorRefId + ' .' + message + ' . Consulte el log para ver el detalle';
  MessageService.showMessage(MessageType.Error,"Error en la aplicación",msg);  
  
  const data ={
    userAgent:navigator.userAgent,
    errorType:'Window Error',
    message:message,
    source: source,
    lineno:lineno,
    colno:colno,
    error:error

  }

  const trace = {
    error : JSON.stringify(data),
    errorRefId:lastErrorRefId,        
  };
    setError(trace);

}

const logExceptionApiService= (error) : void => {
  const router = useRouter();
  if (error.request.responseURL.endsWith('/security/logout'))
  {
      return;
  }
    let tittle = 'Error';
    
  // sesión no activa
  const response = error.response.data;
  if (response.msgCode == 'bllSecurity.login.001' || response.msgCode == 'bllSecurity.checkSecurity.002'){
      //const router = useRouter();      
      if (router && router.currentRoute.value.name == 'login'){        
          tittle='Error de acceso al sistema';
      }
      if (router &&  router.currentRoute.value.name != 'login' && response.msgCode == 'bllSecurity.checkSecurity.002'){                
          MessageService.showMessage(MessageType.Error,tittle,response.message.toString(),true,false,false,'',HelperCommon.salir);            
      }
      else{
        MessageService.showMessage(MessageType.Error,tittle,response.message.toString());    
      }
  }
  else
  {
    MessageService.showMessage(MessageType.Error,tittle,response.message.toString());
  }
  
}

const logExceptionApiServiceData= (data) : void => {  
  const response = data.data;
  const msgs = response.messages ?? [];
  
  if (response.error) 
  {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const msg_str = msgs.map(value => value.description).join(",");
        MessageService.showMessage(MessageType.Error,"Error",msg_str);
  } 
/*   else
  {
        if (msgs.length > 0){
          const msg_str = msgs.map(value => value.description).join(",");
          MessageService.showMessage(MessageType.Info,"Aviso",msg_str);
        }        
  }  */
}



export default { logException, logExceptionW, logExceptionApiService, logExceptionApiServiceData };