import { App } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { AxiosResponse, AxiosRequestConfig } from 'axios';

/**
 * @description service to call HTTP request via Axios
 */
class ApiNostromo {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>, urlBase: string) {
        ApiNostromo.vueInstance = app;
        ApiNostromo.vueInstance.use(VueAxios, axios);
        ApiNostromo.vueInstance.axios.defaults.baseURL = urlBase; // process.env.BASE_URL;// "http://localhost";
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.get(resource, params).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get(resource: string, slug = '' as string): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.get(`${resource}/${slug}`).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.post(`${resource}`, params).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.put(`${resource}/${slug}`, params).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.put(`${resource}`, params).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiNostromo.vueInstance.axios.delete(resource).catch((error) => {
            throw new Error(`ApiNostromo ${error}`);
        });
    }
}

export default ApiNostromo;
