

import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import ConfigurationConst from '@frontcommon/shared/src/constantes/ConfigurationConst';
import HelperConfiguration from '@frontcommon/shared/src/funciones/HelperConfiguration';
import HelperLoading from '@frontcommon/shared/src/funciones/HelperLoading';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import logoComp from '@frontcommon/shared/src/funcionescompartidas/logoComp';

export default defineComponent({
    name: 'AppFooter',
    setup() {
        const {getLogoUrl, ipublicPath} = logoComp();
        const store = useStore();        
        let footerText = ref('');

        const getFooterText = () => {
            HelperLoading.showLoading();

            HelperConfiguration.getVersion().then((data) => {
                footerText.value += data.version + ' | ' +
                    HelperCommon.getCurrentDateTimeLongFormat() + ' | ' + store.getters.getKey(ConfigurationConst.COPYRIGHT);
            }).finally(() => {
                HelperLoading.hideLoading();
            })

        }


        const getCookieDomnain = computed(() => {
            return store.getters.getKey(ConfigurationConst.COOKIES) ?? '';
        })



        onMounted(()=>{
            getFooterText();
        })

        return {
            footerText,
            getCookieDomnain,
            ipublicPath,
            getLogoUrl
        };
    },
});
