import { menu } from "@frontcommon/shared/src/modelos/menu/Menu";

/**
 * @description return init data menus
 */
export const initMenus = (): menu[] => {
    
    
    const data : menu[] = ([
        {
            label: 'Inicio',
            icon: 'pi pi-fw pi-home',
            directivas: '',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Mi panel', icon: 'pi pi-fw pi-home', url:'', target:'', to: '/mipanel', directivas: '', builtin: '', contrato: '' },
                { label: 'Mi perfil', icon: 'pi pi-fw pi-user', url:'', target:'',to: '/miperfil', directivas: '', builtin: '', contrato: '' },
                { label: 'Notificaciones', icon: 'pi pi-fw pi-volume-up',url:'', target:'', to: '/notificaciones', directivas: '', builtin: '', contrato: '' },
                { label: 'Agenda', icon: 'pi pi-fw pi-inbox', url:'', target:'',to: '/agenda', directivas: '', builtin: '', contrato: '' },
            ],
        },
        /*{
            label: 'Productos',
            icon: 'pi pi-fw pi-sitemap',
            directivas: 'rfp_acceso',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Productos WillPlatine', icon: 'pi pi-fw pi-shopping-cart',url:'', target:'', to: '/tarificador', directivas: 'rfp_acceso', builtin: '', contrato: '' },
                { label: 'Sales Manager', icon: 'pi pi-fw pi-external-link', url: process.env.VUE_APP_SALESMANAGER_URL ?? '', target: '_blank' , to: '', directivas: '', builtin: '', contrato: ''},
            ],
        },
        */
        {
            label: 'Oportunidades',
            icon: 'pi pi-fw pi-briefcase',
            directivas: 'rfp_acceso',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Solicitudes', icon: 'pi pi-fw pi-folder-open',url:'', target:'', to: '/rfps/', directivas: 'rfp_acceso', builtin: '', contrato: '' },
                { label: 'Listado', icon: 'pi pi-fw pi-list',url:'', target:'', to: '/rfpslist', directivas: 'rfp_list', builtin: '', contrato: '' },
            ],
        },
        {
            label: 'Administración',
            icon: 'pi pi-fw pi-folder',
            directivas: 'admin',
            builtin: '',
            contrato: 'true',
            items: [

                { label: 'Suscriptores', icon: 'pi pi-fw pi-envelope', url: '', target: '', to: '/suscriptores', directivas: 'admin', builtin: '', contrato: 'true' },
                { label: 'Usuarios', icon: 'pi pi-fw pi-user', url: '', target: '', to: '/users', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Permisos', icon: 'pi pi-fw pi-key', to: '/permissions', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Grupos', icon: 'pi pi-fw pi-users', to: '/groups', directivas: 'admin', builtin: 'true', contrato: 'true' },
                { label: 'Corredores', icon: 'pi pi-fw pi-shopping-bag', url: '', target: '', to: '/brokers', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Figuras', icon: 'pi pi-fw pi-users', to: '/userfigure', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Agrupaciones', icon: 'pi pi-fw pi-list', to: '/brokergroups', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Compañías', icon: 'pi pi-fw pi-list', to: '/cias', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Suscriptores', icon: 'pi pi-fw pi-list', to: '/subscribers', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Productos', icon: 'pi pi-fw pi-list', to: '/products', directivas: 'admin', builtin: 'true', contrato: 'true' },
                { label: 'Filtros de vehículos', icon: 'pi pi-fw pi-car', url:'', target:'', to: '/vehiclefilters', directivas: 'admin', builtin: 'true', contrato: 'true' }
        //        { label: 'Configuración', icon: 'pi pi-fw pi-list', to: '/config', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Comisiones', icon: 'pi pi-fw pi-list', to: '/commissiongroups', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Parrilla de control', icon: 'pi pi-fw pi-list', to: '/controlgrid', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Eventos', icon: 'pi pi-fw pi-list', to: '/events', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Certificados email', icon: 'pi pi-fw pi-list', to: '/mailcerts', directivas: 'admin', builtin: '', contrato: '' },
        //        { label: 'Certificados RGPD', icon: 'pi pi-fw pi-list', to: '/rgpdcerts', directivas: 'admin', builtin: '', contrato: '' },
        //        { label: 'Repositorio', icon: 'pi pi-fw pi-list', to: '/repository', directivas: 'admin', builtin: '', contrato: '' },
            ],
        },
        {
            label: 'Sistema',
            icon: 'pi pi-fw pi-folder',
            directivas: 'directives',
            builtin: '',
            contrato: 'true',
            items: [
                // { label: 'Directivas', icon: 'pi pi-fw pi-folder-open', url:'', target:'', to: '/directives', directivas: 'sysadmin', builtin: 'true', contrato: 'true' },
                // { label: 'Opciones', icon: 'pi pi-fw pi-folder-open', to: '/options', directivas: 'sysadmin', builtin: 'true', contrato: 'true' },
                // { label: 'Logs Sistema', icon: 'pi pi-fw pi-folder-open', to: '/systemlogs', directivas: 'systemlogs', builtin: 'true', contrato: 'true' },
                { label: 'Web Configs', icon: 'pi pi-fw pi-folder-open', url:'', target: '', to: '/webconfigs', directivas: 'sysadmin', builtin: 'true', contrato: 'true' },
            ],
        },
        {
            label: 'Integración',
            icon: 'pi pi-fw pi-sitemap',
            directivas: 'admin',
            builtin: '',
            contrato: 'true',
            items: [
                { label: 'Pólizas', icon: 'pi pi-fw pi-arrow-right-arrow-left', url:'', target:'', to: '/integrationpolicies', directivas: 'admin', builtin: 'true', contrato: 'true' },
                { label: 'WS externos', icon: 'pi pi-fw pi-arrow-circle-up', url:'', target:'', to: '/wsexternal', directivas: 'admin', builtin: 'true', contrato: 'true' },
            ],
        },
        {
            label: 'Herramientas',
            icon: 'pi pi-cog',
            directivas: 'tools_acceso',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Gestión', icon: 'pi pi-book',url:'', target:'', to: '/management', directivas: 'tools_acceso', builtin: 'true', contrato: '' },
                { label: 'Documentación', icon: 'pi pi-fw pi-folder-open' ,url:'', target:'', to: '/documentum', directivas: 'tools_acceso', builtin: 'true', contrato: '' },
                { label: 'Panel de documentos', icon: 'pi pi-book',url:'', target:'', to: '/productdocs', directivas: 'tools_acceso', builtin: 'true', contrato: '' },
                { label: 'Notificaciones', icon: 'pi pi-bell',url:'', target:'', to: '/notifications', directivas: 'tools_acceso;masscommunication', builtin: 'true', contrato: '' },
            ],
        },
        {
            label: 'Clientes',
            icon: 'pi pi-user',
            directivas: 'admin',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Gestión', icon: 'pi pi-book',url:'', target:'', to: '/clientes', directivas: 'admin', builtin: '', contrato: '' }
            ],
        },
/*
        {
            label: 'Clientes',
            icon: 'pi pi-user',
            directivas: 'tools_acceso',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Clientes', icon: 'pi pi-book',url:'', target:'', to: '/management', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Pólizas', icon: 'pi pi-fw pi-folder-open' ,url:'', target:'', to: '/documentum', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Recibos', icon: 'pi pi-book',url:'', target:'', to: '/productdocs', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Riesgos', icon: 'pi pi-bell',url:'', target:'', to: '/notifications', directivas: 'tools_acceso;masscommunication', builtin: '', contrato: '' },
                { label: 'Siniestros', icon: 'pi pi-bell',url:'', target:'', to: '/notifications', directivas: 'tools_acceso;masscommunication', builtin: '', contrato: '' },
            ],
        },

/*        {
            label: 'Administración',
            icon: 'pi pi-cog',
            directivas: 'tools_acceso',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Contabilidad', icon: 'pi pi-book',url:'', target:'', to: '/management', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Liquidaciones', icon: 'pi pi-fw pi-folder-open' ,url:'', target:'', to: '/documentum', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Aduanas', icon: 'pi pi-book',url:'', target:'', to: '/productdocs', directivas: 'tools_acceso', builtin: '', contrato: '' },
            ],
        },
        {
            label: 'Ilinium',
            icon: 'pi pi-cog',
            directivas: 'tools_acceso',
            builtin: '',
            contrato: '',
            items: [
                { label: 'Producto', icon: 'pi pi-book',url:'', target:'', to: '/management', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Catalogos', icon: 'pi pi-fw pi-folder-open' ,url:'', target:'', to: '/documentum', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Compañías', icon: 'pi pi-book',url:'', target:'', to: '/productdocs', directivas: 'tools_acceso', builtin: '', contrato: '' },
                { label: 'Usuarios', icon: 'pi pi-bell',url:'', target:'', to: '/notifications', directivas: 'tools_acceso;masscommunication', builtin: '', contrato: '' },
            ],
        },
     
     */
     
     
//{
        //    label: 'Administración',
        //    icon: 'pi pi-fw pi-folder',
        //    directivas: 'admin',
        //    builtin: '',
        //    contrato: 'true',
        //    items: [
        //        { label: 'Usuarios', icon: 'pi pi-fw pi-user', to: '/adminusers', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Permisos', icon: 'pi pi-fw pi-key', to: '/permissions', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Grupos', icon: 'pi pi-fw pi-users', to: '/groups', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Corredores', icon: 'pi pi-fw pi-shopping-bag', to: '/brokers', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Figuras', icon: 'pi pi-fw pi-users', to: '/userfigure', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Agrupaciones', icon: 'pi pi-fw pi-list', to: '/brokergroups', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Compañías', icon: 'pi pi-fw pi-list', to: '/cias', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Suscriptores', icon: 'pi pi-fw pi-list', to: '/subscribers', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Productos', icon: 'pi pi-fw pi-list', to: '/products', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Filtros de vehículos', icon: 'pi pi-fw pi-list', to: '/vehiclefilters', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Configuración', icon: 'pi pi-fw pi-list', to: '/config', directivas: 'admin', builtin: '', contrato: 'true' },
        //        { label: 'Comisiones', icon: 'pi pi-fw pi-list', to: '/commissiongroups', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Parrilla de control', icon: 'pi pi-fw pi-list', to: '/controlgrid', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Eventos', icon: 'pi pi-fw pi-list', to: '/events', directivas: 'admin', builtin: 'true', contrato: 'true' },
        //        { label: 'Certificados email', icon: 'pi pi-fw pi-list', to: '/mailcerts', directivas: 'admin', builtin: '', contrato: '' },
        //        { label: 'Certificados RGPD', icon: 'pi pi-fw pi-list', to: '/rgpdcerts', directivas: 'admin', builtin: '', contrato: '' },
        //        { label: 'Repositorio', icon: 'pi pi-fw pi-list', to: '/repository', directivas: 'admin', builtin: '', contrato: '' },
        //    ],
        //},        
        //{
        //    label: 'Estadísticas',
        //    icon: 'pi pi-fw pi-folder',
        //    directivas: 'stats_acceso',
        //    builtin: '',
        //    contrato: 'true',
        //    items: [{ label: 'Estadísticas', icon: 'pi pi-fw pi-folder-open', to: '/statistics', directivas: 'stats_acceso', builtin: '', contrato: 'true' }],
        //},
      
        //{
        //    label: 'Oficina Virtual',
        //    icon: 'pi pi-fw pi-folder',
        //    directivas: 'oficinavirtual',
        //    builtin: '',
        //    contrato: '',
        //    items: [
        //        { label: 'Consultas', icon: 'pi pi-fw pi-folder-open', to: '/ovqueries', directivas: 'oficinavirtual', builtin: '', contrato: '' },
        //        { label: 'Sinkroniza', icon: 'pi pi-fw pi-folder-open', to: '/sinkroniza', directivas: 'oficinavirtual', builtin: '', contrato: '' },
        //        { label: 'Solicitudes', icon: 'pi pi-fw pi-folder-open', to: '/ovrequests', directivas: 'oficinavirtual', builtin: '', contrato: '' },
        //    ],
        //},
    ]);
    return data;
};



export default { initMenus };
