import { createRouter, createWebHistory } from 'vue-router';
import store from '@frontcommon/shared/src/almacen/index';

const routes = [
    {
        path: '/',
        redirect: '/mipanel',
    },
    {
        path: '/mipanel',
        name: 'mipanel',
        meta: {
            breadcrumb: [{ parent: 'Inicio', label: 'Mi panel' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        component: () => import('./paginas/panel/Panel.vue'),
    },
    {
        path: '/miperfil',
        name: 'miperfil',
        meta: {
            breadcrumb: [{ parent: 'Inicio', label: 'Mi perfil' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
      
        component: () => import('./paginas/inicio/MiPerfil.vue'),
    },
    {
        path: '/notificaciones/:tipo?',
        name: 'notificaciones',
        meta: {
            breadcrumb: [{ parent: 'Inicio', label: 'Notificaciones' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        component: () => import('./paginas/inicio/Notificaciones.vue'),
    },
    {
        path: '/agenda',
        name: 'agenda',
        meta: {
            breadcrumb: [{ parent: 'Inicio', label: 'Agenda' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        component: () => import('./paginas/agenda/Agenda.vue'),
    },
    {
        path: '/rfps/:id?:operation?:withlock?:query?',
        name: 'rfps',
        meta: {
            breadcrumb: [{ parent: 'Oportunidades', label: 'Solicitudes de cotización' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        component: () => import('./paginas/solicitud/Solicitudes.vue'),
    },
    {
        path: '/rfpslist',
        name: 'rfpslist',
        meta: {
            breadcrumb: [{ parent: 'Oportunidades', label: 'Listado de solicitudes' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        /*beforeEnter() {
            window.open('https://www.github.com/', '_blank')
          },
        */
        component: () => import('./paginas/solicitud/SolicitudesListado.vue'),
        /*component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
        props: { url: '/rfps/rfpslist.aspx' } */
    },
     {
        path: '/login',
        name: 'login',
        component: () => import('./paginas/login/Login.vue'),
    },

    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: () => import('./paginas/login/ForgotPassword.vue'),
    },

    {
        path: '/redsysok',
        name: 'redsysok',
        component: () => import('./paginas/redsys/RedsysOk.vue'),
    },

    {
        path: '/redsysko',
        name: 'redsysko',
        component: () => import('./paginas/redsys/RedsysOk.vue'),
    },


    {
        path: '/management',
        name: 'management',
        meta: {
            breadcrumb: [{ parent: 'Herramientas', label: 'Gestión' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        /*beforeEnter() {
            window.open('https://www.github.com/', '_blank')
          },
        */
        component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
        props: { url: '/tools/gestion.aspx' }         
    },
    {
        path: '/documentum',
        name: 'documentum',
        meta: {
            breadcrumb: [{ parent: 'Herramientas', label: 'Documentación' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        /*beforeEnter() {
            window.open('https://www.github.com/', '_blank')
          },
        */
          //component: () => import('./paginas/herramientas/Documentacion.vue')    
          component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
          props: { url: '/tools/documentum.aspx' }         
  
    },
    {
        path: '/productdocs',
        name: 'productdocs',
        meta: {
            breadcrumb: [{ parent: 'Herramientas', label: 'Panel de Documentos' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        /*beforeEnter() {
            window.open('https://www.github.com/', '_blank')
          },
        */
          //component: () => import('./paginas/herramientas/PanelDocumentos.vue')
          component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
          props: { url: '/tools/productDocuments.aspx' }              
    },   
    {
        path: '/vehiclefilters',
        name: 'vehiclefilters',
        meta: {
            breadcrumb: [{ parent: 'Administracion', label: 'Filtros de vehículos' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        /*beforeEnter() {
            window.open('https://www.github.com/', '_blank')
          },
        */
          component: () => import('./paginas/administracion/FiltrosVehiculos.vue')
          //component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
          //props: { url: '/admin/vehiclefilters.aspx' }         

    },   
    {
        path: '/users',
        name: 'users',
        meta: {
            breadcrumb: [{ parent: 'Administracion', label: 'Usuarios' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },        
          component: () => import('./paginas/administracion/Usuarios.vue')
    },
    {
        path: '/brokers',
        name: 'brokers',
        meta: {
            breadcrumb: [{ parent: 'Administracion', label: 'Corredores' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },        
          component: () => import('./paginas/administracion/corredores.vue')
    },
    {
        path: '/suscriptores',
        name: 'suscriptores',
        meta: {
            breadcrumb: [{ parent: 'Administracion', label: 'Suscriptores' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },        
          component: () => import('./paginas/administracion/Suscriptores.vue')
    },
    {
        path: '/webconfigs',
        name: 'webconfigs',
        meta: {
            breadcrumb: [{ parent: 'Sistema', label: 'Web Configs' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },        
          component: () => import('./paginas/sistema/WebConfigs.vue')
    },
    {
        path: '/integrationpolicies',
        name: 'integrationpolicies',
        meta: {
            breadcrumb: [{ parent: 'Integracion', label: 'Integración' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        //component: () => import('./paginas/integracion/Polizas.vue')
        component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
        props: { url: '/admin/integrationPolicies.aspx' }         
    },   
    {
        path: '/wsexternal',
        name: 'wsexternal',
        meta: {
            breadcrumb: [{ parent: 'Integracion', label: 'WS Externos' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        /*beforeEnter() {
            window.open('https://www.github.com/', '_blank')
          },
        */
          component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
          props: { url: '/admin/externalServices.aspx' }         

    },   
    {
        path: '/notifications',
        name: 'notifications',
        meta: {
            breadcrumb: [{ parent: 'Herramientas', label: 'Notificaciones' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
        /*beforeEnter() {
            window.open('https://www.github.com/', '_blank')
          },
        */
        component: () => import('@frontcommon/shared/src/componentes/base/RedirectPage.vue'),
        props: { url: '/tools/massCommunications.aspx' }         
    },
    {
        path: '/clientes',
        name: 'clientes',
        meta: {
            breadcrumb: [{ parent: 'Clientes', label: 'Gestión de Clientes' }],
            directivas: '',
            builtin: '',
            contrato: '',
        },
          component: () => import('./paginas/clientes/Clientes.vue')
    },   

    
   
    { path: '/:pathMatch(.*)*', name:'notfound', component: () => import('./paginas/app/NotFound.vue') },
];


let router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior() {
        window.scrollTo(0, 0);
        return 0;
        //return { left: 0, top: 0 };
    },
});
//router.beforeEach((to, from, next) => {
router.beforeEach(async (to, next) => {

    //store.dispatch(Actions.VERIFY_AUTH);
    if (to.name !== 'login' && store.getters.isUserAuthenticated) {
        var canAccess = store.getters.hasAccess(to.name, routes);
        if (!canAccess) {
            next({
                path: 'login',
                replace: true,
            });
        }
    }

});

export const initRouter = (routes) =>{
    router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes,
    
        scrollBehavior() {
            window.scrollTo(0, 0);
            return 0;
            //return { left: 0, top: 0 };
        },
    });
}


export default router;
