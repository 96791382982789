

import { defineComponent, ref } from 'vue';

export default defineComponent({
    name: 'cargando',
    setup() {
        let show = ref(false);
        function mostrar() {
            show.value = true;
        };
        function ocultar() {
            show.value = false;
        };
        return {
            show,
            mostrar,
            ocultar,

        };
    }
});
