
import ApiNostromo from '../../servicios/ApiNostromo';
import LocalService from '../../servicios/LocalService';
import { Actions, Mutations } from '../enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { user } from '../../interfaces/nostromo/service/generic/user';
import { _whoAmiRequest } from '@frontcommon/shared/src/modelos/login/_whoAmiRequest';
import CookieService from '../../servicios/CookieService';
import HelperSecurity from '../../funciones/HelperSecurity';
import HelperRfp from '../../funciones/HelperRfp';
import { menu as MenuApp} from '../../modelos/menu/Menu';

export interface userAuthInfo {
    _user: user;
    isAuthenticated: boolean;
    sessionId: string;
    menusApp: MenuApp[];
}

@Module
export default class AuthModule extends VuexModule implements userAuthInfo {
    sessionId = '';
    _user = {} as user;
    isAuthenticated = false;
    menusApp=[];

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): user {
        return this._user;
    }
    get sessionID(): string {
        return this.sessionId;
    }
    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.sessionId != undefined && this.sessionId != '';
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get userName(): string {
        return this._user.name;
    }

    get currentUserId(): string {
        return this._user?.id ?? '';
    }
    get initUrlPage(): string {
        return this._user?.initUrlPage ?? 'mipanel';
    }
    /**
     * Verify user authentication
     * @returns boolean
     */
    get currentBrokerId(): string {
        return this._user?.broker?.id ?? '';
    }

    get currentBrokerLogo(): string {
        return this._user?.broker?.logoUrl ?? '';
    }

    get appMenu(): MenuApp[] {
        return this.menusApp; 
    }

    get hasAccess(): any {        
            const user = this._user;
            
            return function (name: string, routes: any) {
                return HelperSecurity.canUserAccess(name,routes,user);
                
            }
    }

        get menuUser(): any {        
            const user = this._user;
            const menus = JSON.parse(JSON.stringify(this.menusApp));
            return function () {
                
                return HelperSecurity.buildUserMenu(user,menus);
                
            }
        }

        get encrypt(): any {        
            const sessionId = this.sessionID;
            
            return function ( data: string) {
                return HelperSecurity.doEncrypt(sessionId,true,data);
                
            }
        }

        get decrypt(): any {        
            const sessionId = this.sessionID;
            
            return function ( data: string) {
                return HelperSecurity.doEncrypt(sessionId,false,data);
                
            }
        }

        get UnlockRfp(): any {        
            const sessionId = this.sessionID;
            const user = this._user;
            
            return function ( rfpId: string, rfpList: []) {
                return HelperRfp.UnlockRfp(sessionId,user,rfpId,rfpList);
            }
        }

        get DeleteRfp(): any {        
            const sessionId = this.sessionID;
            
            return function ( rfpId: string, rfpList: []) {
                return HelperRfp.DeleteRfp(sessionId,rfpId,rfpList);
            }
        }

        get RfpActionsEnabled(): any {        
            const user = this._user;
            
            return function ( rfp: any) {
                HelperRfp.rfpActionsEnabled(user, rfp);
            }
        }

        get ValidateDirective(): any {        
            const user = this._user;
            
            return function ( directiveId: string) {
                HelperSecurity.validateDirective(user,directiveId);
            }
        }


    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.sessionId = user.sessionID;
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this._user = user;
        // creamos las cookies
        CookieService.setCookie(CookieService.NOSTROMO_BROKER, this._user?.broker?.id ?? '', 60 * 60 * 24 * 30); // caduca en un mes
        // CookieService.setCookie(CookieService.SEGURPASS, this._user?.id ?? '', 60 * 60 * 24 * 5); // caduca en 5 dias
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this._user.password = password;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        this.sessionId = '';
        this.isAuthenticated = false;
        this._user = {} as user;            
        LocalService.destroyAll();
        CookieService.destroyAll();
    }


    @Action
    [Actions.LOGIN](request) {
        return new Promise<void>((resolve, reject) => {
            ApiNostromo.post('/security/login', request)
                .then(({ data }) => {                    
                    this.context.commit(Mutations.SET_AUTH, data)
                        this.context.dispatch(Actions.GET_CONFIG).then( ()=>
                            this.context.dispatch(Actions.VERIFY_AUTH).then(() => {                                
                                resolve();
                            })
                    );                    
                })
                .catch(() => {
                    reject();
                });
        });
    }

    @Action
    [Actions.LOGOUT]() {
        /*
        const data: any = {
            sessionId: this.sessionId,
        };
        */
        return new Promise<void>((resolve) => {
            this.context.commit(Mutations.PURGE_AUTH);
            resolve();
/*            
            ApiNostromo.post('/security/logout', data)
                .then(() => {
                    this.context.commit(Mutations.PURGE_AUTH);
                    resolve();
                })
                .catch(() => {
                    this.context.commit(Mutations.PURGE_AUTH);
                    reject();
                });
                */
        });
    }

    @Action
    [Actions.REGISTER](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiNostromo.post('registration', credentials)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(() => {                    
                    reject();
                });
        });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiNostromo.post('/security/passwordSetResetRequest', payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    @Action
    [Actions.VERIFY_AUTH]() {
        if (this.isUserAuthenticated) {
            const data: any = new _whoAmiRequest(true, true, this.sessionId);
            ApiNostromo.post('/security/whoami', data)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_USER, data.user);
                })
                .catch(() => {                    
                    this.context.commit(Mutations.PURGE_AUTH);
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.UPDATE_USER](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiNostromo.post('update_user', payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_USER, data);
                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }


    @Mutation
    [Mutations.SET_MENU](data) {
        this.menusApp=data;
    }

    @Action
    [Actions.SET_MENU](data) {
        this.context.commit(Mutations.SET_MENU, data);        
    }

}
