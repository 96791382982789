

import { defineComponent, ref } from 'vue';
import { suggestModel } from '../../../../interfaces/nostromo/service/generic/suggestModel';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import HelperReport from '../../../../funciones/HelperReport';
import MessageService, { MessageType } from '../../../../servicios/MessageService';
import { useStore } from 'vuex';
import HelperLoading from '../../../../funciones/HelperLoading';
import HelperCommon from '../../../../funciones/HelperCommon';

export default defineComponent({
    name: 'sugerencias',
    props: ['isError'],
    emits:['doClose'],
    setup(props,context) {

        const store = useStore();
        let data = ref<suggestModel>({
            sessionID: store.getters.sessionID,
            title: '',
            name: '',
            phone: '',
            description: ''

        });

        const submitted = ref(false);


        const rules = {

            title: {
                required: helpers.withMessage('Debe especificar el titulo ', required),
            },
            description: {
                required: helpers.withMessage('Debe especificar la descripción', required),
            },
        };



        const v$ = useVuelidate(rules, data as any);




        const isValidData = (): boolean => {
            submitted.value = true;

            // se valida todo
            v$.value.$touch();
            if (v$.value.$invalid) {
                HelperCommon.setFocusOnFirstError();
                return false;
            }

            return true;
        }



        const sendSuggest = () => {
            if (isValidData()) {
                HelperLoading.showLoading();
                if (!(props.isError ?? false)) {
                    HelperReport.setSuggest(data.value as any).then((response) => {
                        if (response) {
                            MessageService.showToast(MessageType.Correcto, '', 'Se ha generado la sugerencia');
                            initData();
                            context.emit('doClose');

                        }
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    })
                }
                else {
                    HelperReport.setError(data.value as any).then((response) => {
                        if (response) {
                            MessageService.showToast(MessageType.Correcto, '', 'Se ha informado del error');
                            initData();
                            context.emit('doClose');
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    })
                }

            }
        }

        const initData = () => {
            data.value = {
                sessionID: store.getters.sessionID,
                title: '',
                name: '',
                phone: '',
                description: ''

            }
            submitted.value = false;
        }



        return {
            store,
            data,
            submitted,
            isValidData,
            sendSuggest,
            initData,
            rules,
            v$,
        };
    },
});
