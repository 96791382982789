import ApiNostromo from '../servicios/ApiNostromo';

import { getUsersRequest } from '../interfaces/nostromo/service/getUsersRequest';
import { getUsersResponse } from '../interfaces/nostromo/service/getUsersResponse';
import { setUsersRequest } from '../interfaces/nostromo/service/setUsersRequest';
import { setUsersResponse } from '../interfaces/nostromo/service/setUsersResponse';

import { getUsersExternalLinkRequest } from '../interfaces/nostromo/service/getUsersExternalLinkRequest';
import { getUsersExternalLinkResponse } from '../interfaces/nostromo/service/getUsersExternalLinkResponse';
import { keyValue } from '../interfaces/nostromo/service/generic/keyValue';
import HelperCatalog from './HelperCatalog';
import { user } from '../interfaces/nostromo/service/generic/user';
import { brokerExternalLink } from '../interfaces/nostromo/service/generic/brokerExternalLink';
import { rfp } from '../interfaces/nostromo/service/generic/rfp';
import { rfpCompanyPrice } from '../interfaces/nostromo/service/generic/rfpCompanyPrice';
import { userExternalLink } from '../interfaces/nostromo/service/generic/userexternallink';
import HelperExternal from './HelperExternal';
import { setUserRfpUsersRequest } from '../interfaces/nostromo/service/setUserRfpUsersRequest';
import { setUserRfpUsersResponse } from '../interfaces/nostromo/service/setUserRfpUsersResponse';
import { userRfpUser } from '../interfaces/nostromo/service/generic/userRfpUser';
import { setUserProductsRequest } from '../interfaces/nostromo/service/setUserProductsRequest';
import { setUserProductsResponse } from '../interfaces/nostromo/service/setUserProductsResponse';
import { userProducts } from '../interfaces/nostromo/service/generic/userProducts';
import { getUserProductsRequest } from '../interfaces/nostromo/service/getUserProductsRequest';
import { getUserProductsResponse } from '../interfaces/nostromo/service/getUserProductsResponse';
import { setUsersExternalLinkRequest } from '../interfaces/nostromo/service/setUsersExternalLinkRequest';
import { setUsersExternalLinkResponse } from '../interfaces/nostromo/service/setUsersExternalLinkResponse';



const GetUser = (sessionId, userId): Promise<Partial<user>> => {
    return new Promise<Partial<user>>((resolve, reject) => {
        const request: Partial<getUsersRequest> = {
            sessionID: sessionId,
            all: false,
            id: userId,
            withDetails: true,
            allowDefaultUsers: true,
            withBrokerDetails: true,
        };
        ApiNostromo.post('/user/getUser', request as any)
            .then((response) => {                
                resolve(response.data.users.length > 0 ? response.data.users[0] : undefined);
            })
            .catch(() => {
                reject();
            });
    });
};
const GetUsers = (request: Partial<getUsersRequest>): Promise<getUsersResponse> => {
    return new Promise<getUsersResponse>((resolve, reject) => {
        ApiNostromo.post('/user/getUsers', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

const SetUser = (sessionId: string, userRequest: user): Promise<setUsersResponse> => {
    // const users_req: Partial<user>[] = [userRequest];
    const SetUserRequest: setUsersRequest = {
        sessionID: sessionId,
        ignorePasswordPolicy: false,
        users: [userRequest],
    };    
    return new Promise<setUsersResponse>((resolve, reject) => {
        ApiNostromo.post('/user/setUsers', SetUserRequest as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

const getUsersExternalLink = (request: getUsersExternalLinkRequest): Promise<getUsersExternalLinkResponse> => {
    return new Promise<getUsersExternalLinkResponse>((resolve, reject) => {
        ApiNostromo.post('/user/getUsersExternalLink', request as any)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject();
            });
    });
};

const getUserOptions = (sessionid, optionType, userLinks, brokerLinkId, brokerLinkName): Promise<keyValue[]> => {
    return new Promise<keyValue[]>((resolve, reject) => {
        const data: keyValue[] = [];
        if (optionType != null && optionType != '') {
            HelperCatalog.getCatalog(sessionid, [optionType])
                .then((catalogs) => {
                    catalogs.forEach((c) => {
                        userLinks
                            .filter((q) => q.brokerExternalLinkId == brokerLinkId && q.link?.toUpperCase() == brokerLinkName.toUpperCase() && q.option.toLowerCase() == c.id.toLowerCase())
                            .forEach((ulink) => {
                                const _data: Partial<keyValue> = {
                                    id: ulink.option,
                                    description: ulink.value,
                                };
                                data.push(_data as any);
                            });
                    });
                    resolve(data);
                })
                .catch(() => {
                    reject();
                });
        } else {
            userLinks
                .filter((q) => q.brokerExternalLinkId == brokerLinkId && q.link.toUpperCase() == brokerLinkName.toUpperCase())
                .forEach((ulink) => {
                    const _data: Partial<keyValue> = {
                        id: ulink.option,
                        description: ulink.value,
                    };
                    data.push(_data as any);
                });
            resolve(data);
        }
    });
};

// DEPRECATED
const getUserQueryString = (bLink: brokerExternalLink, userOptions: keyValue[], whoAmIUser: user): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        try {
            let query = '';
            switch (bLink.type ? bLink.type.toUpperCase() : '') {
                case 'IS': {
                    const _user = userOptions.find((t) => t.id == 'usuario')?.description ?? '';
                    const _pass = userOptions.find((t) => t.id == 'contraseña')?.description ?? '';
                    const credentials = 'uofv=' + _user + '&pofv=' + _pass;
                    query = bLink.linkUrl + (bLink.embedded ?? false ? '?embedded=true' + '&' : '?') + credentials;
                    break;
                }
                case 'AV': {
                    const _Bsso = userOptions.find((t) => t.id == 'BusinessUnit')?.description ?? '';
                    const _Usso = userOptions.find((t) => t.id == 'UserName')?.description ?? '';
                    const auth = '&BId=' + (bLink.id ?? '') + '&UId=' + (whoAmIUser.id ?? '') + '&Name=' + bLink.name + '&BSSO=' + _Bsso + '&USSO=' + _Usso;
                    query = bLink.linkUrl + (bLink.embedded ?? false ? '?embedded=true' + '&' : '?') + 'auth=' + auth;
                    break;
                }
                default: {
                    let parameters = '';
                    userOptions.forEach((kvp) => {
                        parameters += (parameters == '' ? '' : '&') + kvp.id + '=' + kvp.description;
                    });
                    query = bLink.linkUrl + (bLink.embedded ?? false ? '?embedded=true' + (parameters == '' ? '' : '&') : parameters == '' ? '' : '?') + parameters;
                    break;
                }
            }

            query = 'typ=' + (bLink.type ? '' : bLink.type.toUpperCase()) + '&emb=' + (bLink.embedded ?? false ? 'true' : 'false') + '&url=' + query;

            //query =  "tools/externallink.aspx?query=" + System.Web.HttpUtility.UrlEncode(Encrypt(query));
            resolve(query);
        } catch (ex) {
            reject();
        }
    });
};

const actionViewSalesManagerVisible = (sessionID: string, user: user, solicitud: rfp, brokerExternalLinks: brokerExternalLink[], userExternalLinks: userExternalLink[], rfpCompanyPrices: rfpCompanyPrice[]): Promise<{ out: boolean; url: string }> => {
    return new Promise<{ out: boolean; url: string }>((resolve, reject) => {
        let out = false;
        let url = '';
        try {
            if (solicitud.product.companies.filter((q) => q.connection.id === 'avant2').length > 0) {
                const bLink = brokerExternalLinks.find((b) => b.type == 'AV');
                if (bLink != null) {
                    if (rfpCompanyPrices != null && rfpCompanyPrices.filter((p) => (p.quotationUrl ?? '') !== '').length > 0) {
                        const data = rfpCompanyPrices.find((p) => (p.quotationUrl ?? '') !== '');
                        if (data) {
                            url = data.quotationUrl;
                            out = url != null && url !== '';

                            resolve({ out, url });
                        }
                    } else {
                        HelperExternal.getExternalUrl(sessionID, user.id, user?.broker?.id, 'avant').then((url) => {
                            out = url != null && url !== '';

                            resolve({ out, url });
                        });
                    }
                }
            } else {
                const avant2cia = solicitud.companies.find((q) => q.externalQuoteURL);
                if (avant2cia != null) {
                    url = avant2cia.externalQuoteURL;
                    out = url != null && url !== '';

                    resolve({ out, url });
                }
            }
        } catch {
            out = false;
            url = '';
            reject({ out, url });
        }
    });
};

const getExternalLinks = (sessionId, userId, brokerId, withOptions = true): Promise<{ brokerExternalLinks: brokerExternalLink[]; userExternalLinks: userExternalLink[] }> => {
    return new Promise<{ brokerExternalLinks: brokerExternalLink[]; userExternalLinks: userExternalLink[] }>((resolve, reject) => {
        const request: Partial<getUsersExternalLinkRequest> = {
            all: false,
            sessionID: sessionId,
            brokerId: brokerId,
            userId: userId,
            withOptions: withOptions,
        };
        getUsersExternalLink(request as any)
            .then((response) => {
                if (!response.error) {
                    resolve({ brokerExternalLinks: response.brokerExternalLink ?? [], userExternalLinks: response.usersExternalLink ?? [] });
                }
            })
            .catch(() => {
                reject();
            });
    });
};

const setExternalLinks = (sessionId: string, usersExternalLinkRequest: userExternalLink[]): Promise<setUsersExternalLinkResponse> => {
    const request: setUsersExternalLinkRequest = {
        sessionID: sessionId,
        usersExternalLink:  usersExternalLinkRequest as any,
    };        
    return new Promise<setUsersExternalLinkResponse>((resolve, reject) => {
        ApiNostromo.post('/user/setUsersExternalLink', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


const hasExpiredPassword = (usuario:user) =>{
    
    if (usuario && usuario.passwordNeverExpire == false)
    {
        if (usuario.passwordExpireDate != null)
        {
            const today = new Date();
            const pwddate = new Date(usuario.passwordExpireDate)
            if ( pwddate < today )
            {
                return true;
            }
        }
        // Si su contraseña expira pero no está especificada la fecha, le obligamos a reestablecerla
        else
        {
            return true;
        }
    }
}

const setUserRfpUsers = (sessionId: string, userRfpUser:Partial<userRfpUser>): Promise<setUserRfpUsersResponse> => {
    const request: setUserRfpUsersRequest = {
        sessionID: sessionId,
        userRfpUser: userRfpUser as any,
    };        
    return new Promise<setUserRfpUsersResponse>((resolve, reject) => {
        ApiNostromo.post('/user/setUserRfpUsers', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


// const setUserProducts = (request: setUserProductsRequest): Promise<setUserProductsResponse> => {
//     return new Promise<setUserProductsResponse>((resolve, reject) => {
//         ApiNostromo.post('/product/setUserProducts', request as any)
//             .then((response) => {
//                 resolve(response.data);
//             })
//             .catch(() => {
//                 reject();
//             });
//     });
// };

const setUserProducts = (sessionId: string, userProductRequest: userProducts[]): Promise<setUserProductsResponse> => {
    const request: setUserProductsRequest = {
        sessionID: sessionId,
        userProducts: userProductRequest,
    };        
    return new Promise<setUserProductsResponse>((resolve, reject) => {
        ApiNostromo.post('/product/setUserProducts', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}

const getUserProducts = (sessionId: string,  productId: string | null, userId: string | null, brokerId: string | null): Promise<getUserProductsResponse> => {
    const request: getUserProductsRequest = {
        sessionID: sessionId,
        productId: productId,
        userId: userId,
        brokerId: brokerId,
        all: false
    };   
    return new Promise<getUserProductsResponse>((resolve, reject) => {
        ApiNostromo.post('/product/getUserProducts', request as any)
            .then((response) => {
                resolve(response.data);
            }).catch(() => {
                reject();
            });
    });
}


export default 
{
    GetUser,
    SetUser,
    GetUsers,
    getUsersExternalLink,
    getUserOptions,
    getUserQueryString,
    actionViewSalesManagerVisible,
    getExternalLinks,
    hasExpiredPassword,
    setUserRfpUsers, 
    setUserProducts,
    getUserProducts,
    setExternalLinks,
};
