import ApiNostromo from '../servicios/ApiNostromo';

import { versionInfo } from '../interfaces/nostromo/service/versionInfo';
export const ENDPOINT_GET = '/version/getVersion';
export const ENDPOINT_GET_CONFIG = '/configuration/getConfigPortal';

const getVersion = (): Promise<versionInfo> => {
    return new Promise<versionInfo>((resolve, reject) => {

            ApiNostromo.get(ENDPOINT_GET)
                .then((response) => {
                    resolve(response.data ?? '');
                })
                .catch(() => {
                    reject();
                });
    });
};


const getConfigPortal = (sessionId:string,key:string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
            const request ={
                sessionID:sessionId,
                key:key
            }
            ApiNostromo.post(ENDPOINT_GET_CONFIG,request as any)
                .then((response) => {
                    resolve(response.data ?? '');
                })
                .catch(() => {
                    reject();
                });
    });
};


export default {
    getVersion,
    getConfigPortal
};