
import { computed, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { Actions } from '@frontcommon/shared/src/almacen/enums/StoreEnums';
import { forgetPasswordRequest } from '@frontcommon/shared/src/interfaces/nostromo/service/forgetPasswordRequest';
import { user } from '@frontcommon/shared/src/interfaces/nostromo/service/generic/user';
import MessageService, { MessageType } from '@frontcommon/shared/src/servicios/MessageService';
import HelperCommon from '@frontcommon/shared/src/funciones/HelperCommon';
import HelperLoading from '@frontcommon/shared/src/funciones/HelperLoading';
import ConfigurationConst from '@frontcommon/shared/src/constantes/ConfigurationConst';
import PlatformType from '@frontcommon/shared/src/constantes/PlatformType';


export default {
    name: 'forgotpassword',
    props: ['isForgetPassChild'],
    emits: ['update:isForgetPassChild'],
    setup(props,context) {
        const store = useStore();
        const emailControl = ref<HTMLElement | null>(null);
        const emailConfirmControl = ref<HTMLElement | null>(null);
        const emailconfirm = ref('');
        const ipublicPath = inject('publicPath');

        const user_req: Partial<user> = {
            email: ''
        };

        const request = ref<forgetPasswordRequest>({
            user: user_req as any,
            remoteApplication: 'Portal',
            remoteIp: '',
            requestedUrl: '',
        });

        function validateData(): boolean {
            if (!validateEmail(request.value.user.email)) {
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'Debe indicar un e-mail válido.');
                return false;
            }
            if (request.value.user.email != emailconfirm.value) {
                MessageService.showMessage(MessageType.Error, 'Error de validación', 'Las direcciones de e-mail no coinciden.');
                return false;
            }

            return true;
        }

        function validateEmail(email) {
            return HelperCommon.validarEmail(email);
        }

        const onSubmitForgotPassword = () => {
            // Clear existing errors
            if (validateData()) {
                // Dummy delay
                HelperLoading.showLoading();
                    // Send resetPassword request
                    store.dispatch(Actions.FORGOT_PASSWORD, request.value).then(() => {                        
                        MessageService.showToast(MessageType.Correcto, '', 'Se ha enviado el correo de reestablecimiento de contraseña correctamente.');
                        context.emit('update:isForgetPassChild');
                        //router.push({ name: 'forgotpassword' });
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    });
            }
        };

        const getLogoUrl = computed(() =>{
            let urlLogo = `${ipublicPath}layout/images/logo_wp.png`;
            var platform = store.getters.getKey(ConfigurationConst.PLATFORM) ??  PlatformType.WP;            
            if (platform.toLowerCase()=== PlatformType.AM ){
                urlLogo = `${ipublicPath}layout/images/logo_aunna.png`;
            }

            return urlLogo;
        })

        return {
            request,
            emailControl,
            emailConfirmControl,
            emailconfirm,
            onSubmitForgotPassword,
            getLogoUrl,
            ipublicPath
        };
    },
};
